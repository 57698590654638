import moment from 'moment'
import {
  CANADIAN_LEGAL_AGES,
  ALLOWED_PROVINCES,
  POSTAL_CODE_START_BY_PROVINCE,
  CONSUMER_MAX_AGE
} from 'utils/constants'

export const isEmpty = object => Object.keys(object).length === 0

export const isValid = value =>
  value === 0 || value === false || (typeof value === 'string' ? value.trim().length > 0 : value)

const dateRegex = /^(?:(?!0000)[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01]))$/

const poboxRegex = /((((p\.?o\.?)|(post office)) box?)|p\.?o\.?b\.?|boite postale)( |-)?#?(\d+)?/i
const postalCodeRegex = /^[A-Z]{1}[0-9]{1}[A-Z]{1}\s?[0-9]{1}[A-Z]{1}[0-9]{1}$/

export const dobIsValid = () => value => {
  if (!value) return false
  if (!dateRegex.test(value)) return false

  const dateParts = value.split('/')
  const year = parseInt(dateParts[0], 10)
  const month = parseInt(dateParts[1], 10)
  const day = parseInt(dateParts[2], 10)

  // Check if provided year is not greater than the current year
  const currentYear = new Date().getFullYear()
  if (year < 1900 || year >= currentYear) return false

  // Check if the day is valid for the given month
  const lastDayOfMonth = new Date(year, month, 0).getDate()
  if (day < 1 || day > lastDayOfMonth) return false

  return true
}

export const dobIsUnderaged = province => value => {
  if (!value) return true
  const legalAge = CANADIAN_LEGAL_AGES[province]
  const dob = moment(value, 'YYYY/MM/DD')
  const age = moment().diff(dob, 'years')
  return age >= legalAge
}

export const dobIsOverAged = () => value => {
  if (!value) return true
  const legalOverAge = CONSUMER_MAX_AGE
  const dob = moment(value, 'YYYY/MM/DD')
  const age = moment().diff(dob, 'years')
  return age < legalOverAge
}

export const isPhoneNumberValid = value => {
  if (!value) return true
  const formattedPhoneNumber = value.replace(/[^0-9]+/g, '')
  return formattedPhoneNumber.length === 10
}

export const isAddressIncomplete = values => () => {
  const { address, province, city, postalCode } = values
  if (address && address.length > 0) return isValid(province) && isValid(city) && isValid(postalCode)
  return true
}

export const isAllowedProvince = province => () => {
  if (province && province.length > 0) return ALLOWED_PROVINCES.includes(province)
  return true
}

export const isAddressPOBox = values => {
  const { address } = values
  return poboxRegex.test(address)
}

export const isPostalCode = postalCode => {
  return !postalCode || postalCodeRegex.test(postalCode)
}

export const isPostalCodeValid = () => value => {
  if (!value) return true
  return isPostalCode(value)
}

export const isPostalCodeProvinceValid = () => value => {
  if (!value) return true
  if (!isPostalCode(value)) return false

  const correspondingProvince = getProvinceFromPostalCode(value)
  return isPostalCodeAllowed(value, correspondingProvince)
}

export const isPostalCodeValidWithProvince = (postalCode, province) => {
  if (!isPostalCode(postalCode)) return false
  const provinceLetters = Object.entries(POSTAL_CODE_START_BY_PROVINCE).find(([, pcLetters]) =>
    pcLetters.includes(postalCode[0])
  )
  if (!provinceLetters) return false
  return !province || province === provinceLetters[0]
}

export const isPostalCodeAllowed = (postalCode, province) => {
  if (!isPostalCode(postalCode)) return false
  if (province && province.length > 0) return ALLOWED_PROVINCES.includes(province)
  return true
}

export const getProvinceFromPostalCode = postalCode => {
  const provinceLetters = Object.entries(POSTAL_CODE_START_BY_PROVINCE).find(([, pcLetters]) =>
    pcLetters.includes(postalCode[0])
  )

  return provinceLetters ? provinceLetters[0] : ''
}

export const PASSWORD_VALIDATION_CONDITIONS = {
  minlength: {
    message: 'Must be at least 8 characters',
    isCompliant: null
  }
}

export const testPassword = (value, context) => {
  const conditions = {
    minlength: value && value.length >= 8
  }

  return Object.values(conditions).some(condition => !condition)
    ? context.createError({ message: 'Must be at least 8 characters', path: context.path })
    : true
}

export const getPasswordValidationCondition = ({ passwordValue, passwordError }) => {
  const passwordValidationDefault = PASSWORD_VALIDATION_CONDITIONS
  let passwordValidation = passwordValidationDefault

  if (passwordValue) {
    if (passwordError && typeof passwordError === 'object') {
      passwordValidation = Object.fromEntries(
        Object.entries(passwordValidationDefault).map(([key]) => [
          key,
          {
            message: passwordValidationDefault[key].message,
            isCompliant: !passwordError[key]
          }
        ])
      )
    } else {
      passwordValidation = Object.fromEntries(
        Object.entries(passwordValidationDefault).map(([key]) => [
          key,
          {
            message: passwordValidationDefault[key].message,
            isCompliant: passwordValue.length >= 8
          }
        ])
      )
    }
  }

  return passwordValidation
}

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

export const isUserObjValid = user => {
  if (user.email === undefined || user.password === undefined || user.agentSource === undefined) {
    return false
  }

  return true
}
